import './loaderAnimation.css'

export default function LoaderAnimation (){
    return(
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>)
}